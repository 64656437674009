
/*
  color palette:
  https://coolors.co/362517-462c19-623e27-826957-cfaa90
*/
body {
  color: #362517;
  font-size: 24px;
  font-family: moret, serif;
font-weight: 400;
font-style: normal;
  font-weight: 400;
  background-color: #cfaa90;
  font-size: 16px;
}

header, footer {
  background: black;
  padding: 10px 4px;
  color: #988986;
  font-size: 16px;
  letter-spacing: .8px;
}

header a, footer a {
  color: white;
}
p {
  font-family: ff-good-web-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
}


h1 {
  font-family: blenny, sans-serif;
  font-weight: 900;
  font-size: 50px;
  color: #362517;
}


.header a:hover {
  text-decoration: none;
}
a {
  color: #362517;
  text-decoration: none;
}
.card a {
  color: white;
}
a:hover {
  color:  #826957;
  //text-decoration: none;
}

.badge {
  font-weight: 700;
  font-size: 12px;
}
.badge-primary {
  background-color: #623e27 !important;
}
.btn-secondary {
  background-color: #988986;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


h4, h5 {

  font-family: moret, serif;
  font-weight: 700;
  font-style: normal;
}
.card {

  font-family: ff-good-web-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: #462C19;
  min-height: 12rem;
  color:  white;
  border: 2px solid #462C19;
  box-shadow: 6px 6px 1px #826957;
}

.heart {
  font-size: 2rem;
}

p {
  padding: 0;
  font-size: 14px;
}

p, h6, .badge {

  margin-bottom: 8px;
}

@media only screen and (max-width: 480px) {
  h1 {
    font-size: 32px;
    letter-spacing: 0.1rem;
  }
  .heart {
    font-size: 1.4rem;
    margin-right: 4px;
  }
  .btn-group span.btn-text {
    display: none;
  }
  .card {

  box-shadow: 3px 3px 6px #826957;
}

}

@media only screen and (max-width: 360px) {
  h1 {
    font-size: 25px;
    letter-spacing: 0.1rem;
  }
  .heart {
    font-size: 1rem;
    margin-right: 1rem;
  }
  .btn-group span.btn-text {
    display: none;
  }
  .card {

  box-shadow: 3px 3px 6px #826957;
}

}

